export const getReadableVideoDurationFromSeconds = (input?: string | number): string => {
  // Ensure input is a valid number
  const seconds = typeof input === 'string' ? parseFloat(input) : input

  if (Number.isNaN(seconds) || seconds === undefined) {
    return '00:00.00'
  }

  const minutes = Math.floor(seconds / 60)
  const secs = Math.floor(seconds % 60)
  const millis = ((seconds % 1) * 100).toFixed(0).padStart(2, '0')

  return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}.${millis}`
}

export const getReadableDurationFromSeconds = (seconds?: string): string => {
  if (!seconds) return 'N/A'

  let numberedSeconds = parseFloat(seconds)
  if (!numberedSeconds) return 'N/A'

  const hoursDifference = Math.floor(numberedSeconds / 60 / 60)
  numberedSeconds -= hoursDifference * 60 * 60
  const minutesDifference = Math.floor(numberedSeconds / 60)
  numberedSeconds -= minutesDifference * 60
  const secondsDifference = Math.floor(numberedSeconds)
  numberedSeconds -= secondsDifference

  const hoursString = hoursDifference.toString().padStart(2, '0')
  const minutesString = minutesDifference.toString().padStart(2, '0')
  const secondsString = secondsDifference.toString().padStart(2, '0')

  return `${hoursString}h ${minutesString}m ${secondsString}s`
}
